import React from 'react';
import SliderShow from './SliderShow';

function Banner() {
  return (
    <div className="banner">
      <div className="container">
        <img src='./img/ЛУИТЩУВШИВ.jpg' alt='banner wine' />
      </div>
    </div>
  );
}

export default Banner;
