import React from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { updateEvent, fetchOneEvent } from '../../http/productApi';

const defaultValue = {
  name: '',
  place: '',
  link: '',
  description: '',
  date: '',
  responsible: '',
  whatsapp: '',
  telegram: '',
};
const defaultValid = {
  name: null,
  place: null,
  link: null,
  whatsapp: null,
  telegram: null,
  description: null,
  date: null,
  responsible: null,
};

const isValid = (value) => {
  const result = {};
  for (let key in value) {
    if (key === 'name') result.name = value.name.trim() !== '';
    if (key === 'place') result.place = value.place.trim() !== '';
    if (key === 'link') result.link = value.link.trim() !== '';
    if (key === 'whatsapp') result.whatsapp = value.whatsapp.trim() !== '';
    if (key === 'telegram') result.telegram = value.telegram.trim() !== '';
    if (key === 'description') result.description = value.description.trim() !== '';
    if (key === 'date') result.date = value.date.trim() !== '';
    if (key === 'responsible') result.responsible = value.responsible.trim() !== '';
  }
  return result;
};

const UpdateEvent = (props) => {
  const { id, show, setShow, setChange } = props;
  const [value, setValue] = React.useState(defaultValue);
  const [valid, setValid] = React.useState(defaultValid);
  const [image, setImage] = React.useState(null);

  React.useEffect(() => {
    if (show) {
      fetchOneEvent(id)
        .then((data) => {
          const prod = {
            name: data.name.toString(),
            place: data.place.toString(),
            link: data.link.toString(),
            whatsapp: data.whatsapp.toString(),
            telegram: data.telegram.toString(),
            description: data.description.toString(),
            date: data.date.toString(),
            responsible: data.responsible.toString(),
          };
          setValue(prod);
          setValid(isValid(prod));
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            alert(error.response.data.message);
          } else {
            console.log('An error occurred');
          }
        });
    }
  }, [show, id]);

  const handleInputChange = (event) => {
    const data = { ...value, [event.target.name]: event.target.value };
    setValue(data);
    setValid(isValid(data));
  };

  const handleImageChange = (event) => {
    setImage(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const correct = isValid(value);
    setValid(correct);

    const data = new FormData();
    data.append('name', value.name.trim());
    data.append('place', value.place.trim());
    data.append('link', value.link.trim());
    data.append('whatsapp', value.whatsapp.trim());
    data.append('telegram', value.telegram.trim());
    data.append('description', value.description.trim());
    data.append('date', value.date.trim());
    data.append('responsible', value.responsible.trim());
    if (image) data.append('image', image, image.name);

    updateEvent(id, data)
      .then((data) => {
        const prod = {
          name: data.name.toString(),
          place: data.place.toString(),
          link: data.link.toString(),
          whatsapp: data.whatsapp.toString(),
          telegram: data.telegram.toString(),
          description: data.description.toString(),
          date: data.date.toString(),
          responsible: data.responsible.toString(),
        };
        setValue(prod);
        setValid(isValid(prod));
        setChange((state) => !state);
        setShow(false);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          alert(error.response.data.message);
        } else {
          console.log('An error occurred');
        }
      });
  };

  return (
    <Modal show={show} onHide={() => setShow(false)} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Новое мероприятие</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Control
            name="name"
            value={value.name}
            onChange={(e) => handleInputChange(e)}
            isValid={valid.name === true}
            isInvalid={valid.name === false}
            placeholder="Название мероприятия..."
            className="mb-3"
          />
          <Row className="mb-3">
            <Col>
              <Form.Control
                name="place"
                value={value.place}
                onChange={(e) => handleInputChange(e)}
                isValid={valid.place === true}
                isInvalid={valid.place === false}
                placeholder="Место проведения..."
              />
            </Col>
            <Col>
              <Form.Control
                name="date"
                value={value.date}
                onChange={(e) => handleInputChange(e)}
                isValid={valid.date === true}
                isInvalid={valid.date === false}
                placeholder="Дата проведения..."
              />
            </Col>
            <Col>
              <Form.Control
                name="link"
                value={value.link}
                onChange={(e) => handleInputChange(e)}
                isValid={valid.link === true}
                isInvalid={valid.link === false}
                placeholder="Ссылка на оплату..."
              />
            </Col>
            <Col>
              <Form.Control
                name="whatsapp"
                value={value.whatsapp}
                onChange={(e) => handleInputChange(e)}
                isValid={valid.whatsapp === true}
                isInvalid={valid.whatsapp === false}
                placeholder="WhatsApp..."
              />
            </Col>
            <Col>
              <Form.Control
                name="telegram"
                value={value.telegram}
                onChange={(e) => handleInputChange(e)}
                isValid={valid.telegram === true}
                isInvalid={valid.telegram === false}
                placeholder="Telegram..."
              />
            </Col>
            <Col>
              <Form.Control
                name="responsible"
                value={value.responsible}
                onChange={(e) => handleInputChange(e)}
                isValid={valid.responsible === true}
                isInvalid={valid.responsible === false}
                placeholder="Отвественный..."
              />
            </Col>
            <Col>
              <Form.Control
                name="image"
                type="file"
                onChange={(e) => handleImageChange(e)}
                placeholder="Фото товара..."
              />
            </Col>
          </Row>
          <Col>
            <textarea
              name="description"
              multiline={true}
              value={value.description}
              onChange={(e) => handleInputChange(e)}
              isValid={valid.description === true}
              isInvalid={valid.description === false}
              placeholder="Описание..."
              style={{ height: '500px', width: '100%' }}
            />
          </Col>
          <Row>
            <Col>
              <Button type="submit">Сохранить</Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateEvent;
